import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { IS_BROWSER, NODE_ENV } from '@shiftsmartinc/remix-utils';
import {
  SHIFTSMART_RUM_ENABLED,
  SHIFTSMART_SAMPLE_RATE,
} from '@shiftsmartinc/remix-utils';
import { IUser } from '@shiftsmartinc/shiftsmart-types';
import { APP_NAME, APP_VERSION } from '~/global/config';

/**
 * @description Initialize our Datadog logging and RUM integrations. See the
 * links below for more information around the configuration options.
 */
export const init = () => {
  if (!IS_BROWSER) return;

  /**
   * @external https://docs.datadoghq.com/logs/log_collection/javascript
   * @external https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
   * @description Send logs to Datadog from web browser pages with the browser logs SDK.
   */
  datadogLogs.init({
    clientToken: 'pubf7ce200376081678c8c4f7c827b54d99',
    env: NODE_ENV,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    service: APP_NAME,
    sessionSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    site: 'datadoghq.com',
    telemetryConfigurationSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    telemetrySampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    version: APP_VERSION,
  });

  if (!SHIFTSMART_RUM_ENABLED) return;

  /**
   * @external https://docs.datadoghq.com/real_user_monitoring/browser
   * @external https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
   * @external https://docs.datadoghq.com/integrations/rum_react
   * @description Datadog Real User Monitoring (RUM) enables you to visualize
   * and analyze the real-time performance and user journeys of your
   * application’s individual users.
   */
  datadogRum.init({
    allowedTracingUrls: [
      {
        match: 'https://api.shiftsmart.com',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'https://platform.shiftsmart.com',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'http://127.0.0.1:9090',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'http://localhost:9090',
        propagatorTypes: ['tracecontext'],
      },
    ],
    applicationId: 'd187d25e-7b08-4433-84b1-75a0ba0b2c8e',
    clientToken: 'pubf7ce200376081678c8c4f7c827b54d99',
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
    env: NODE_ENV,
    service: APP_NAME,
    sessionReplaySampleRate: 25, // Record 25% of the "SHIFTSMART_SAMPLE_RATE %" sessions recorded
    sessionSampleRate: SHIFTSMART_SAMPLE_RATE * 100, // ##% of browsers sessions we track
    site: 'datadoghq.com',
    telemetryConfigurationSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    telemetrySampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    traceSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    trackFrustrations: true,
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    trackUserInteractions: true,
    version: APP_VERSION,
  });

  // 📹 Start session replay recording
  datadogRum.startSessionReplayRecording();
};

/**
 * @description Sets up the RUM user if in the browser context, and it doesn't exist already.
 */
export function setUser(user?: IUser): void {
  if (!IS_BROWSER || !user?.email) return;

  const rumUser = datadogRum.getUser() ?? {};

  if (!rumUser.email || !rumUser.id || !rumUser.name) {
    const datadogUser = {
      email: user.email,
      id: user.uuid,
      name: `${user.firstName} ${user.lastName}`,
    };

    // datadogLogs.setUser(datadogUser);
    datadogRum.setUser(datadogUser);
  }
}

/**
 * @description Deletes the RUM user if exists.
 */
export function deleteUser(): void {
  if (!IS_BROWSER) return;

  datadogRum.clearUser();
}
